import React, { useContext } from 'react';
import Nav from './Nav';
import Footer from './Footer';
import { Link } from "react-router-dom";
import { AuthContext } from './Auth';
import logo from './logo.yellow.png';


export default function Home() {
    const {currentUser} = useContext(AuthContext);
    return (
        <div>
            <Nav />            
            {/* <!-- Page Content --> */}
            <div className="container">

                {/* <!-- Jumbotron Header --> */}
                <header className="jumbotron my-4">
                    {/* <img className="w-auto" src={logo} alt="Logo"/> */}
                    <h1 className="display-3">Hello, {currentUser.displayName}</h1>
                    <p className="lead">Go to <Link to="/movies" className="">Movies</Link> to search for content to add.</p>
                    <p className="lead">Right now, <Link to="/tv" className="">TV</Link> is not setup yet.</p>
                    <div className="btn-group mr-2" role="group" aria-label="First group">
                        <a href="http://m.me/MovieDraneBot" className="btn btn-primary btn-lg"><i className="fab fa-facebook-messenger"></i> MovieDraneBot</a>
                    </div>
                    <div className="btn-group mr-2" role="group" aria-label="First group">
                        <a className="btn btn-lg btn-warning" href="https://app.plex.tv/"><i className="fas fa-play"></i> Web Player</a>
                    </div>
                    <div className="btn-group mr-2" role="group" aria-label="First group">
                        <a href="https://itunes.apple.com/us/app/plex/id383457673?mt=8"><img width="150" src="https://www.plex.tv/wp-content/uploads/2018/01/apple-app-badge-150x44.png" className="attachment-150x0 size-150x0" alt="" srcSet="https://www.plex.tv/wp-content/uploads/2018/01/apple-app-badge-150x44.png 150w, https://www.plex.tv/wp-content/uploads/2018/01/apple-app-badge-300x89.png 300w, https://www.plex.tv/wp-content/uploads/2018/01/apple-app-badge-768x228.png 768w, https://www.plex.tv/wp-content/uploads/2018/01/apple-app-badge-1024x304.png 1024w, https://www.plex.tv/wp-content/uploads/2018/01/apple-app-badge-1800x534.png 1800w, https://www.plex.tv/wp-content/uploads/2018/01/apple-app-badge-1440x427.png 1440w, https://www.plex.tv/wp-content/uploads/2018/01/apple-app-badge-800x237.png 800w, https://www.plex.tv/wp-content/uploads/2018/01/apple-app-badge-640x190.png 640w, https://www.plex.tv/wp-content/uploads/2018/01/apple-app-badge-400x119.png 400w, https://www.plex.tv/wp-content/uploads/2018/01/apple-app-badge.png 1963w" sizes="(max-width: 150px) 100vw, 150px"/></a>
                    </div>
                    <div className="btn-group mr-2" role="group" aria-label="First group">
                        <a href="https://play.google.com/store/apps/details?id=com.plexapp.android&amp;hl=en"><img width="150" src="https://www.plex.tv/wp-content/uploads/2018/01/android-app-badge-150x44.png" className="attachment-150x0 size-150x0" alt="" srcSet="https://www.plex.tv/wp-content/uploads/2018/01/android-app-badge-150x44.png 150w, https://www.plex.tv/wp-content/uploads/2018/01/android-app-badge-300x88.png 300w, https://www.plex.tv/wp-content/uploads/2018/01/android-app-badge-768x226.png 768w, https://www.plex.tv/wp-content/uploads/2018/01/android-app-badge-1024x301.png 1024w, https://www.plex.tv/wp-content/uploads/2018/01/android-app-badge-1800x529.png 1800w, https://www.plex.tv/wp-content/uploads/2018/01/android-app-badge-1440x423.png 1440w, https://www.plex.tv/wp-content/uploads/2018/01/android-app-badge-800x235.png 800w, https://www.plex.tv/wp-content/uploads/2018/01/android-app-badge-640x188.png 640w, https://www.plex.tv/wp-content/uploads/2018/01/android-app-badge-400x118.png 400w, https://www.plex.tv/wp-content/uploads/2018/01/android-app-badge.png 2000w" sizes="(max-width: 150px) 100vw, 150px"/></a>
                    </div>
                    <div className="btn-group mr-2" role="group" aria-label="First group">
                        <a className="btn btn-primary btn-lg" href="https://www.plex.tv/apps-devices/"><i className="fas fa-info-circle"></i> Plex Devices</a>
                    </div>
                </header>

                {/* <!-- Page Features --> */}
                <div className="row text-center">

                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card h-100">
                    <img className="card-img-top" src="https://images.unsplash.com/photo-1562516155-e0c1ee44059b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2089&q=80" alt=""/>
                    <div className="card-body">
                        <h4 className="card-title">Beginner? Start Here!</h4>
                        <p className="card-text">We have a Facebook Group and a post there about how to get setup with creating a Plex login to get started watching content!</p>
                    </div>
                    <div className="card-footer">
                        <a href="https://www.facebook.com/groups/MovieDrane/learning_content/?filter=1075320829305945&post=2194835657438947" className="btn btn-primary">View Post</a>
                    </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card h-100">
                    <img className="card-img-top" src="https://images.unsplash.com/photo-1549319114-d67887c51aed?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80" alt=""/>
                    <div className="card-body">
                        <h4 className="card-title">Don't See Drano in Server List?</h4>
                        <p className="card-text">If you just joined, you should have an email invite. Helpful guide to accepting the invite is here. If you haven't received an invite, start a chat with me to get that going. You are also always welcome to chat us anytime.</p>
                    </div>
                    <div className="card-footer">
                        <a href="https://support.plex.tv/articles/201105738-creating-and-managing-server-shares/" className="btn btn-primary">Accept The Invite</a>
                    </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card h-100">
                    <img className="card-img-top" src="https://images.unsplash.com/flagged/photo-1573763955714-e7114250fdaa?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80" alt=""/>
                    <div className="card-body">
                        <h4 className="card-title">Offline Play?</h4>
                        <p className="card-text">Yes you can! Plus, it won't disappear after you play it! (like Disney+ and others)</p>
                    </div>
                    <div className="card-footer">
                        <a href="https://support.plex.tv/articles/201082477-quick-guide-to-mobile-sync/" className="btn btn-primary">Learn More</a>
                    </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card h-100">
                    <img className="card-img-top" src="https://images.unsplash.com/photo-1561883088-039e53143d73?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80" alt=""/>
                    <div className="card-body">
                        <h4 className="card-title">Download For Yourself</h4>
                        <p className="card-text">You are more than welcome to download from my Plex for your personal Plex or whatever.</p>
                    </div>
                    <div className="card-footer">
                        <a href="https://support.plex.tv/articles/201018507-download-media/" className="btn btn-primary">Learn More</a>
                    </div>
                    </div>
                </div>

                </div>
                {/* <!-- /.row --> */}

            </div>
            {/* <!-- /.container --> */}
            <Footer />
        </div>
    )
};