import React, { useCallback } from 'react';
import { withRouter } from "react-router";
import Firebase from './Firebase';
import Nav from './Nav';
import Footer from './Footer';


const SignUp = ({ history }) => {
  const onSubmitHandler = useCallback(async event => {
    event.preventDefault();
    const { email, password } = event.target.elements;
    try {
      await Firebase.auth().createUserWithEmailAndPassword(email.value, password.value);
      history.push('/');
    } catch (error) {
      alert(error);
    }
  }, [history]);

  return (
    <div>
      <Nav />
      <div className="container">
          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <div className="card card-signin my-5">
                <div className="card-body">
                  <h5 className="card-title text-center">Register</h5>
                  <form onSubmit={onSubmitHandler}>
                    <div className="form-label-group">
                      <input type="email" name="email" className="form-control" placeholder="Email address" required autoFocus />
                      <label>Email address</label>
                    </div>

                    <div className="form-label-group">
                      <input type="password" name="password" className="form-control" placeholder="Password" required />
                      <label>Password</label>
                    </div>

                    <button className="btn btn-lg btn-primary btn-block text-uppercase" type="submit">Register</button>
                    
                  </form>
                </div>
              </div>
            </div>
          </div>
      </div>
      <Footer />
    </div>
  )
}

export default withRouter(SignUp);