import React from 'react';

import './App.css';
import Nav from './Nav.jsx';
import TVContent from './TVContent.jsx';
import Footer from './Footer.jsx';


export default function TV() {
    return (
      <div>
          <Nav />
          <TVContent />
          <Footer />
      </div>
    );
  }