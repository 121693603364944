import React, { useContext, useCallback } from 'react';
import { withRouter, Redirect } from "react-router";
import { Link } from "react-router-dom";
import Firebase from './Firebase';
import { AuthContext } from './Auth';
import Nav from './Nav';
import Footer from './Footer';
import { googleProvider, facebookProvider } from './Firebase';

function Login({ history }) {
  const { currentUser } = useContext(AuthContext);

  const onSubmitHandler = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await Firebase.auth().signInWithEmailAndPassword(email.value, password.value);
        history.push('/');
      } catch (error) {
        alert(error);
      }
    }, [history]
  );

  const onClickGoogleHandler = useCallback(
    async event => {
      event.preventDefault();
      try {
        await Firebase.auth().signInWithPopup(googleProvider)
        history.push('/');
      } catch (error) {
        alert(error);
      }
    }, [history]
  )

  const onClickFacebookHandler = useCallback(
    async event => {
      event.preventDefault();
      try {
        await Firebase.auth().signInWithPopup(facebookProvider)
        history.push('/');
      } catch (error) {
        alert(error);
      }
    }, [history]
  )

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Nav />
        {/* <-- LOGIN --> */}
        <div className="container">
          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <div className="card card-signin my-5">
                <div className="card-body">
                  <h5 className="card-title text-center">Sign In</h5>
                  <form className="form-signin" onSubmit={onSubmitHandler}>
                    <div className="form-label-group">
                      <input type="email" name="email" className="form-control" placeholder="Email" required autoFocus />
                      <label>Email address</label>
                    </div>

                    <div className="form-label-group">
                      <input type="password" name="password" className="form-control" placeholder="Password" required />
                      <label>Password</label>
                    </div>

                    <div className="custom-control custom-checkbox mb-3">
                      <input type="checkbox" className="custom-control-input" id="customCheck1" />
                      <label className="custom-control-label">Remember password</label>
                    </div>

                    <button className="btn btn-lg btn-primary btn-block text-uppercase" type="submit">Sign in</button>

                    <hr className="my-4" />

                    <Link className="btn-secondary btn-block text-uppercase" to="/signup">
                      <button 
                        className="btn btn-lg btn-secondary btn-block text-uppercase">
                          <i className="fas fa-at mr-2"></i>
                          Register with Email
                      </button>
                    </Link>
                    <button 
                      className="btn btn-lg btn-block text-uppercase" 
                      style={{color: "white", backgroundColor: "#ea4335"}}
                      onClick={onClickGoogleHandler}>
                        <i className="fab fa-google mr-2"></i>
                        Continue with Google
                    </button>
                    <button 
                      className="btn btn-lg btn-block text-uppercase" 
                      style={{color: "white", backgroundColor: "#3b5998"}}
                      onClick={onClickFacebookHandler}>
                      <i className="fab fa-facebook-f mr-2"></i>
                      Continue with Facebook
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <-- LOGIN --> */}
      <Footer />
    </div>
  );
}

export default withRouter(Login);