import React from 'react';

import './App.css';
import Nav from './Nav.jsx';
import MoviesContent from './MoviesContent.jsx';
import Footer from './Footer.jsx';


export default function Movies() {
    return (
      <div>
          <Nav />
          <MoviesContent />
          <Footer />
      </div>
    );
  }