// import * as firebase from "firebase/app";
// import "firebase/auth";

import firebase from "firebase"

const Firebase = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();

export const addMovie = async (title, year, createdBy, status, magnet) => {
  const addMovieToDb = Firebase.functions().httpsCallable('addMovie')
  const movie = {
    title: title, 
    year: year, 
    createdBy: createdBy, 
    status: status, 
    magnet: magnet
  }
  try {
    const result = await addMovieToDb(movie);
    return result.data;
  } catch (error) {
    alert("addMovieToDb Error: ", error);
  }
  
}

export default Firebase;