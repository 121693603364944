import React, { useContext } from 'react';
import Firebase from './Firebase.js';
import { Link } from "react-router-dom";
import './App.css';
import { AuthContext } from './Auth';
import logo from './logo.yellow.png';


export default function Nav() {
    const {currentUser} = useContext(AuthContext);
    
    // const location = useLocation();
    const location = "/";
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <div className="container">
            <Link to="/" className="navbar-brand w-50">
                {/* <i className="fab fa-youtube"></i> MovieDrane */}
                <img className="w-50" src={logo} alt="Logo"/>
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ml-auto">
                    {location.pathname === "/" ? 
                    <li className="nav-item active">
                        <Link to="/" className="nav-link">Home</Link>
                    </li> :
                    <li className="nav-item">
                        <Link to="/" className="nav-link">Home</Link>
                    </li>
                    }
                    {location.pathname === "/movies" ? 
                    <li className="nav-item active">
                        <Link to="/movies" className="nav-link">Movies</Link>
                    </li> :
                    <li className="nav-item">
                        <Link to="/movies" className="nav-link">Movies</Link>
                    </li>
                    }
                    {location.pathname === "/tv" ? 
                    <li className="nav-item active">
                        <Link to="/tv" className="nav-link"> TV</Link>
                    </li> :
                    <li className="nav-item">
                        <Link to="/tv" className="nav-link">TV</Link>
                    </li>
                    }
                    <button className="nav-item" onClick={() => Firebase.auth().signOut()}>Sign Out</button>
                </ul>
            </div>
        </div>
    </nav>
  );
}
