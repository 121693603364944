import React from 'react';
import {useState} from 'react';
import './App.css';


export default function TVContent() {
  const [search, setSearch] = useState("");
  // const [showSearch, setShowSearch] = useState(false);
  // const onChangeHandler = (event) => {
  //   setShowSearch(false);
  //   setSearch(event.target.value);
  //   alert("Sorry! TV Search Not Working.")
  // }
  return (
    <content>
      {/* <!-- Page Content --> */}
      <div className="container">
        <div className="row">
            {/* <!-- Search Widget --> */}
            <div className="card my-3 col-lg">
              <div className="card-body">
                <div className="input-group">
                  {/* <input type="text" className="form-control" placeholder="Search for..." value={search} onChange={onChangeHandler}/> */}
                  <span className="input-group-btn">
                    {/* <button onClick={() => setShowSearch(true)} className="btn btn-secondary" type="button">Search</button> */}
                  </span>
                </div>
              </div>
            </div>
        </div>
        {/* <!-- Search Results --> */}
        {<header className="jumbotron my-4">
            <h1 className="display-3">TV Not Working Yet</h1>
            <p className="lead">Sorry this is not working right now.</p>
            <a href="http://m.me/MovieDraneBot" className="btn btn-primary btn-lg">Need Help?</a>
          </header>}

      </div>
      {/* <!-- /.container --> */}
    </content>
  );
}
