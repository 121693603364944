import React from 'react';
import {useState, useEffect, useContext} from 'react';
import './App.css';
import Firebase from './Firebase';
import { AuthContext } from './Auth';


export default function Search(props) {
    const {currentUser} = useContext(AuthContext);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [movies, setMovies] = useState([]);
    const trackers = [
      "udp://open.demonii.com:1337/announce",
      "udp://tracker.openbittorrent.com:80",
      "udp://tracker.coppersurfer.tk:6969",
      "udp://glotorrents.pw:6969/announce",
      "udp://tracker.opentrackr.org:1337/announce",
      "udp://torrent.gresille.org:80/announce",
      "udp://p4p.arenabg.com:1337",
      "udp://tracker.leechers-paradise.org:6969",
      "udp://tracker.internetwarriors.net:1337",
    ];
    const yifyUrl = new URL("https://yts.mx/api/v2/list_movies.json");
    const params = new URLSearchParams(yifyUrl.search);
    params.set('query_term', props.search);
    const url = yifyUrl.toString() + "?" + params.toString();
    useEffect(() => {
      fetch(url, {
        method: 'GET',
        mode: 'no-cors',
      })
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setMovies(result.data.movies);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }, []);

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!props.search) {
        return <div>Type a word to search</div>;
    } else if (!isLoaded) {
      return (
        <div className="text-center">
          <div className="spinner-grow" style={{width: "5rem", height: "5rem"}} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )
    } else {
      return (

        <div className="row">

          {movies.map(movie => {
            return (
            <div key={movie.id} className="col-lg-4 col-md-6 mb-4">
                <div className="card h-100">
                    <img className="card-img-top" src={movie.medium_cover_image} alt=""/>
                    <div className="card-body">
                    <h4 className="card-title">{movie.title} ({movie.year})</h4>
                    <p>{movie.summary}</p>
                </div>
                <div className="card-footer">
                    {movie.torrents.map((torrent) => {
                        const title = `${movie.title}+(${movie.year})+[${torrent.quality}]+[YTS.LT]`
                        const magnet = `magnet:?xt=urn:btih:${torrent.hash}&dn=${title}` + trackers.join('&tr=');
                        return (
                            <button 
                              key={torrent.hash} 
                              className={"btn btn-secondary btn-dark"} 
                              disabled={isAdding}
                              onClick={async () => {
                                setIsAdding(true);
                                const db = Firebase.firestore();
                                const data = await db.collection("movies").get();
                                const movies = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                                const exists = movies.find(dbMovie => dbMovie.title === movie.title && dbMovie.year === movie.year);
                                if (!exists) {
                                  db.collection("movies").add({
                                    createdBy: currentUser.email,
                                    createdDate: new Date().toJSON(),
                                    magnet: magnet,
                                    status: "requested",
                                    title: movie.title,
                                    torrentID: "",
                                    torrentName: "",
                                    year: movie.year
                                  });
                                  alert(`Yay! You Added ${movie.title} (${movie.year}), thanks for contributing!!`);
                                } else {
                                  alert(`Oh, looks like ${movie.title} (${movie.year}) is on Plex. Send a chat to me if this is wrong.`);
                                }
                                setIsAdding(false);
                              }}>
                                {isAdding && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                {torrent.quality}
                              </button>
                        )
                    })}
                </div>
                </div>
            </div>
            )
        })}
        </div>
      );
    }
  }