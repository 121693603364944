import React from 'react';
import './App.css';

export default function Footer() {
  return (
    <div>
      {/* <!-- Footer --> */}
      <footer className="py-5 bg-dark">
        <div className="container">
          <p className="m-0 text-center text-white">Copyright &copy; MovieDrane 2020</p>
        </div>
        {/* <!-- /.container --> */}
      </footer>
    </div>
  );
}
