import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import './App.css';
import Movies  from './Movies';
import TV from './TV';
import { AuthProvider } from './Auth';
import Login from './Login';
import PrivateRoute from './PrivateRoute';
import SignUp from './SignUp';
import Home from './Home';


export default function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/movies" component={Movies} />
          <PrivateRoute exact path="/tv" component={TV} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={SignUp} />
        </div>
      </Router>
    </AuthProvider>
  );
}
